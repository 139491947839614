import styled from "styled-components";

const Container =styled.div`
display: flex;
justify-content: center;
align-items: center;
padding: 0 1rem;
background: #fff;
padding: 3rem 0;
@media screen and (max-width: 768px) {
  padding: 0 0;
  } 
`

const Wrapper = styled.div`
padding: 2rem;
border-radius: 15px;
`

const TimelineUl = styled.ul`
list-style:none;
margin-top: 2rem;
border-radius: 12px;
position: relative;
`

const TimeLineli = styled.li`
    padding-bottom: 1.5rem;
    border-left: 1px solid #01417F;
    position: relative;
    padding-left: 20px;
    margin-left: 10px;
  }&:last-child {
    border: 0px;
    padding-bottom: 0;
  }&:before {
    content: "";
    width: 15px;
    height: 15px;
    background:#01417F;
    border: 1px solid #4e5ed3;
    border-radius: 50%;
    position: absolute;
    left: -9px;
    top: 0px;
  }
  @media screen and (max-width: 768px) {
    margin-left: 0;
    } 
`
const TimeLineContent = styled.p`
color: #01417F;
font-family: "Pretendard-Medium","Apple SD Gothic Neo","sans-serif";
font-size: 1.5vw;
line-height: 1.5;
margin-top: 0.4rem;
@media screen and (max-width: 768px) {
  font-size: 2.3vw;
  } 
`




function TimeLine(){
    return(
<Container>
  <Wrapper>
    <TimelineUl>
      <TimeLineli>
        <TimeLineContent>2023 산학협력 EXPO 학생창업 페스티벌 도약트랙 장려상</TimeLineContent>
      </TimeLineli>
      <TimeLineli>
        <TimeLineContent>2023 도전K스타트업 본선진출(경쟁률 31:1), 최종 23위 (예비창업리그 상위 1%)</TimeLineContent>
      </TimeLineli>
      <TimeLineli>
        <TimeLineContent>카카오뱅크 주관 '핀테크·포용금융 핵셀러레이터' 대상</TimeLineContent>
      </TimeLineli>
      <TimeLineli>
        <TimeLineContent>제 4기 SNU 해동 스타트업 데모데이 장려상</TimeLineContent>
      </TimeLineli>
      <TimeLineli>
        <TimeLineContent>2023 사회적기업가육성사업 예비트랙 선발</TimeLineContent>
      </TimeLineli>
      <TimeLineli>
        <TimeLineContent>외 10개 이상 수상</TimeLineContent>
      </TimeLineli>
    </TimelineUl>
  </Wrapper>
</Container>
    );
}

export default TimeLine;

